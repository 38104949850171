import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import Hero from '../components/hero';

function Page() {
  return <Layout>
    <Helmet>
      <title>page 2</title>
    </Helmet>
    <Hero>
      <h1>This is another page, wow.</h1>
      <Link to="/" className="btn">I'm a button</Link>
    </Hero>
    <div className="container">
      <h2>page 2 testing</h2>
      <Link to="/">Link to home page</Link>
    </div>
  </Layout>;
}

export default Page;
